import { IStream } from './IElementary';
import EArray from './ElementaryArray2';

/**
 * Component library for working with the DOM
 * Enables the use of components with plain HTML elements and operations.
 *
 * Elementary aims to be / have:
 * - Simple
 * - Close to the metal; no fancy magic, performant
 * - Extendable
 * - Great support for stream libraries.
 *
 * @export
 * @class Elementary
 * @template T
 */
export class Elementary<T extends {}> {
    protected _el: HTMLElement = null;

    public _plugins: { [key: string]: (that: Elementary<T>) => T };
    public plugins: T = {} as T;

    constructor(el: HTMLElement | string) {
        if (typeof el === 'string') {
            this._el = document.querySelector(el);
        } else {
            this._el = el;
        }

        Object.getOwnPropertyNames(this._plugins).forEach((n) => {
            const plug = this._plugins[n];
            if (typeof plug === 'function') {
                this.plugins[n] = plug(this);
            }
        });
    }

    public get el() {
        return this._el;
    }

    public create(parent: HTMLElement | null, nodeName: string): Elementary<T> {
        const [tag, ...classes] = nodeName.split('.');
        const el = document.createElement(tag);
        if (classes.length > 0) {
            el.className = classes.join(' ');
        }
        if (parent !== null) {
            parent.appendChild(el);
        }
        return new Elementary(el);
    }

    /**
     *
     *
     * @export
     * @template T
     * @param {HTMLElement} el
     * @param {(el, ...args) => HTMLElement} fn
     * @param {*} args
     * @returns {T} new T object (does not mutate existing element)
     */
    public appendComponent(
        el: HTMLElement,
        fn: [string, (el, ...args) => HTMLElement],
        ...args
    ): Elementary<T> {
        const [id, func] = fn;
        const existingComponent = el.querySelector(`#${id}`);
        if (existingComponent) {
            existingComponent.remove();
        }
        const newEl = func(el, ...args);
        newEl.id = id;
        return new Elementary(newEl);
    }

    public append(
        node: string | [string, (el: HTMLElement, ...args) => HTMLElement],
        ...args
    ): Elementary<T> {
        if (typeof node === 'string') {
            return this.create(this._el, node);
        } else {
            return this.appendComponent(this._el, node, ...args);
        }
    }

    public empty(): Elementary<T> {
        this.query('*').remove();
        return this;
    }

    public exec(fn: (el: HTMLElement) => void): Elementary<T> {
        fn(this._el);
        return this;
    }

    public execEl(fn: (el: Elementary<T>) => void): Elementary<T> {
        fn(this);
        return this;
    }

    /**
     * Attaches {handler} to {event} on active element
     *
     * @param {string} event
     * @param {(el: Elementary<T>, e: Event) => void} cb
     * @return {*}  {Elementary<T>}
     * @memberof Elementary
     */
    public on(
        event: string,
        cb: (el: Elementary<T>, e: Event) => void,
    ): Elementary<T> {
        this._el.addEventListener(event, cb.bind(null, this));
        return this;
    }

    public parent() {
        return new Elementary<T>(this._el.parentElement);
    }

    public query(q: string) {
        return new EArray(this._el.querySelectorAll(q));
    }

    public queryOrCreate(q: string, nodeName: string): Elementary<T> {
        const element: HTMLElement = this._el.querySelector(q);
        if (!element) {
            return this.append(nodeName);
        } else {
            return new Elementary(element);
        }
    }
    public remove() {
        const parent = this._el.parentElement;
        this._el.remove();
        return new Elementary(parent);
    }

    /**
     * Sets an {attr} to {val} on the active HTMLElement.
     *
     * @param {string} attr
     * @param {*} val
     * @return {*}  {Elementary<T>}
     * @memberof Elementary
     */
    public setAttribute(attr: string, val: any): Elementary<T> {
        this._el.setAttribute(attr, val);
        return this;
    }

    /**
     * Sets {text} as textContent on active element
     *
     * @param {string} t
     * @return {*}  {Elementary<T>}
     * @memberof Elementary
     */
    public text(t: string): Elementary<T> {
        this._el.textContent = t;
        return this;
    }

    /**
     * Applies {style} to active element
     *
     * @param {Partial<CSSStyleDeclaration>} styles
     * @return {*}  {Elementary<T>}
     * @memberof Elementary
     */
    public style(styles: Partial<CSSStyleDeclaration>): Elementary<T> {
        Object.getOwnPropertyNames(styles).forEach((n) => {
            this._el.style[n] = styles[n];
        });
        return this;
    }

    /**
     * Applies {style} to active element onMouseEnter, and removes {style} onMouseLeave
     *
     * @param {Partial<CSSStyleDeclaration>} styles
     * @return {*}  {Elementary<T>}
     * @memberof Elementary
     */
    public styleOnHover(styles: Partial<CSSStyleDeclaration>): Elementary<T> {
        const old = Object.getOwnPropertyNames(styles).map((n) => {
            return [n, this._el.style[n]];
        });
        this._el.onmouseenter = () => {
            Object.getOwnPropertyNames(styles).forEach((n) => {
                this._el.style[n] = styles[n];
            });
        };
        this._el.onmouseleave = () => {
            old.forEach(([n, v]) => {
                this._el.style[n] = v;
            });
        };
        return this;
    }

    /**
     * Simple support for handling stream events.
     * Requires the stream to fulfill the IStream interface
     *     - This means the stream must have a subscribe method,
     *       providing events of type X
     *
     * @template X
     * @param {IStream<X>} sub
     * @param {(el: Elementary<T>, event: X) => void} handler
     * @return {*}
     * @memberof Elementary
     */
    public onStream<X>(
        sub: IStream<X>,
        handler: (el: Elementary<T>, event: X) => void,
    ) {
        sub.subscribe((e) => {
            handler(this, e);
            return this;
        });
        return this;
    }
}

/**
 * Adds a plugin to Elementary, accessible using <instance>.plugins.<namespace>
 *
 * In order for the plugin to get access to the elementary instance,
 * the {plug} argument must be a function accepting the instance as an argument.
 * The {plug} function should return the instantiated plugin. The function is
 * called *once* from the Elementary constructor
 *
 * @export
 * @template T
 * @param {string} namespace
 * @param {(that: Elementary<T>) => any} plug
 */
export function ElementaryPlugin<T>(
    namespace: string,
    plug: (that: Elementary<T>) => any,
) {
    if (Elementary.prototype._plugins) {
        Elementary.prototype._plugins[namespace] = plug;
    } else {
        Elementary.prototype._plugins = { [namespace]: plug };
    }
}
