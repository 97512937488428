import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isUserEstimateEvent, isUserEvent } from '../../guards';
import { EventTypes, IUserEstimateEvent, IUserEvent, Root$ } from '../../root';
import { navigateTo, PageEnum } from '../../RoutingConstants';
import {
  AddUserToRoom,
  CreateRoom,
  CreateUser,
  GetRoom,
  UpdateEstimate,
  ToggleShowEstimates, RemoveUserFromRoom
} from './graphql/graphql-operations';
import {
  AddUserToRoomMutation,
  CreateRoomMutation,
  CreateUserMutation,
  Estimate,
  GetRoomQuery,
  UpdateEstimateMutation,
  ToggleShowEstimatesMutation,
  Room, RemoveUserFromRoomMutation
} from './graphql/types';
import { client$ } from './state/mongodbrealm';

export const selectedCard$ = new BehaviorSubject<number>(-1);

export const name$ = new BehaviorSubject<string>('');

export const userEstimates$ = new BehaviorSubject<IUserEstimateEvent>({
  type: EventTypes.USER_ESTIMATE,
  estimate: null,
});
export const user$ = new BehaviorSubject<IUserEvent>({
  type: EventTypes.USER,
  user: null,
});

Root$.pipe(filter(isUserEstimateEvent)).subscribe((e) =>
  userEstimates$.next(e),
);

selectedCard$.subscribe((estimate) => {
  if (userEstimates$.value?.estimate) {
    updateEstimate(userEstimates$.value.estimate._id, estimate);
  }
});

export async function updateEstimate(estimateId: string, estimate: number) {
  if (client$.value) {
    client$.value.client.mutate<UpdateEstimateMutation>({
      mutation: UpdateEstimate,
      variables: {
        estimateId,
        estimate,
      },
    });
  }
}

export async function createUser(name: string, roomKey: string) {
  if (client$.value) {
    const client = client$.value.client;

    const room = await client.query<GetRoomQuery>({
      query: GetRoom,
      variables: {
        key: parseInt(roomKey),
      },
    });

    const createUser = await client.mutate<CreateUserMutation>({
      mutation: CreateUser,
      variables: { name },
    });

    if (createUser.data.insertOneEstimate._id) {
      const addUser = await client.mutate<AddUserToRoomMutation>({
        mutation: AddUserToRoom,
        variables: {
          estimateId: createUser.data.insertOneEstimate._id,
          roomId: room.data.room._id,
        },
      });

      if (addUser.data.addUser.key) {
        Root$.next({
          type: EventTypes.USER_ESTIMATE,
          estimate: createUser.data.insertOneEstimate,
        } as IUserEstimateEvent);
        navigateTo(PageEnum.SCRUM_POKER, addUser.data.addUser.key.toString());
      }
    }
  }
}

export async function createRoom(key: number, title: string) {
  if (client$.value) {
    const createRoom = await client$.value.client.mutate<CreateRoomMutation>({
      mutation: CreateRoom,
      variables: { key, title },
    });
    if (createRoom.data.insertOneRoom._id) {
      navigateTo(PageEnum.CREATE_USER, key.toString());
    }
  }
}

export function toggleShowEstimates(id: string, show: boolean) {
  if (client$.value) {
    client$.value.client.mutate<ToggleShowEstimatesMutation>({
      mutation: ToggleShowEstimates,
      variables: { id, show },
    });
  }
}

export function clearEstimates(room: Room) {
  if (client$.value) {
    toggleShowEstimates(room._id, false);
    room.estimates?.forEach((e) => updateEstimate(e._id, -1));
  }
}


export async function removeUser(roomId: string, estimateId: string) {
  if (client$.value) {
    const client = client$.value.client;

    const removeUser = await client.mutate<RemoveUserFromRoomMutation>({
      mutation: RemoveUserFromRoom,
      variables: { roomId, estimateId },
    });
  }
}