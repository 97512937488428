import { Elementary } from '../Elementary';
import { IAnimation, IAnimationProps } from './IAnimate';
import { linear, Easing, easings } from './easingFunctions';
import { step } from './AnimateCore';

const defaultAnimProps: IAnimationProps = {
    x: 0,
    y: 0,
    z: 0,
    scale: 1,
    hueRotate: 0,
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
};

export class Animate<T> {
    private elementaryInstance: Elementary<T>;
    private anim: IAnimation | null;
    private animStages: IAnimation[] = [];
    private activeAnimationFrameHandle: number = -1;
    private animationPromise: Promise<Animate<T>> | null;

    constructor(elementaryInstance: Elementary<T>) {
        this.elementaryInstance = elementaryInstance;
    }

    public init() {
        this.anim = {
            from: { ...defaultAnimProps },
            to: { ...defaultAnimProps },
            current: {},
            currentPercentage: 0,
            duration: 0,
            el: this.elementaryInstance.el,
            yFn: linear,
            ease: Easing.linear,
        };
        return this;
    }

    public elementary() {
        return this.elementaryInstance;
    }

    get state() {
        return this.anim;
    }

    public from(from: IAnimationProps): Animate<T> {
        this.anim.from = { ...defaultAnimProps, ...from };
        return this;
    }

    public to(to: IAnimationProps): Animate<T> {
        this.anim.to = { ...defaultAnimProps, ...to };
        return this;
    }

    public duration(duration: number): Animate<T> {
        this.anim.duration = duration;
        return this;
    }

    public yFn(yFn: (x: number) => number): Animate<T> {
        this.anim.yFn = yFn;
        return this;
    }

    public ease(ease: Easing): Animate<T> {
        this.anim.ease = ease;
        this.anim.yFn = easings[ease];
        return this;
    }

    public open() {
        return this.animationStart();
    }

    public close() {
        console.log('reverse');
        return this.animationStart(true);
    }

    public getAnimationPromise() {
        return {
            continue: () => {
                return this;
            },
            wait: async () => {
                await this.animationPromise;
                this.animStages.push({ ...this.anim });
                this.init();
                return this;
            },
            finish: async () => {
                await this.animationPromise;
                this.animStages = [];
                this.anim = null;
                return this;
            },
        };
    }

    private initAnimation() {
        Object.getOwnPropertyNames(this.anim.from).forEach((p) => {
            this.anim.current[p] = this.anim.from[p];
        });
    }

    private requestAnimFrame(fn: (number) => void) {
        this.activeAnimationFrameHandle = window.requestAnimationFrame(fn);
    }

    private animationStart(reverse = false) {
        this.animationPromise = new Promise<Animate<T>>(async (res, rej) => {
            this.initAnimation();
            this.activeAnimationFrameHandle = window.requestAnimationFrame((timestamp) =>
                step(
                    this,
                    {
                        start: undefined,
                        state: this.anim,
                        res: res,
                        rej,
                    },
                    reverse,
                    timestamp,
                    this.requestAnimFrame.bind(this),
                ),
            );
        });

        return {
            continue: () => {
                return this;
            },
            wait: async () => {
                await this.animationPromise;
                this.anim.currentPercentage = 0;
                this.animStages.push({ ...this.anim });
                return this;
            },
            finish: async () => {
                await this.animationPromise;
                this.animStages = [];
                this.anim = null;
                return this;
            },
        };
    }
}

export const ANIMATE_PLUGIN_NAMESPACE = 'animate';

export interface IAnimatePlugin<T> {
    animate: Animate<T>;
}

export function AnimatePlugin<T>(elementaryInstance: Elementary<T>) {
    return new Animate<T>(elementaryInstance);
}
