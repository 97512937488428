import E from '../../../elementary.setup';
import { Easing } from '../../../lib/animate/easingFunctions';

const CARD_NUMS = [1, 3, 5, 8, 13, 20, 40, 60, 100, '☕'];

export function Cards(
    el: HTMLElement,
    selectedCard: number,
    onSelectCard: (num: number) => void,
) {
    return new E(el)
        .empty()
        .append('div')
        .style({
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
        })
        .execEl((el) =>
            CARD_NUMS.forEach((num) =>
                el.append(
                    [`card-${num}`, Card],
                    num,
                    num === '☕' ? -2 === selectedCard : num === selectedCard,
                    onSelectCard,
                ),
            ),
        ).el;
}

function Card(
    el: HTMLElement,
    num: string,
    selected: boolean,
    onClick: (num: number) => void,
) {
    // prettier-ignore
    return new E(el)
          .append(`div.w-24.h-40.rounded.relative.cursor-pointer.m-4${selected ? '.bg-blue-300' : '.bg-gray-300'}`)
              .on('click', (el) => 
                  el.plugins.animate.init()
                      .from({
                          fillRadial: 'lightgrey',
                          rotateY: 0
                      })
                      .to({
                          fillRadial: '#90cdf4',
                          rotateY: 360
                      })
                      .duration(1)
                      .ease(Easing.easeOutBounce)
                      .open()
                      .finish()
                          .then(() => onClick(num === '☕' ? -2 : parseInt(num.toString())))
              )
              .append('span')
                  .style({
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      transform: 'translateX(-50%) translateY(-50%)',
                  })
                  .text(num.toString())
                  .parent()
              .parent()
              .el;
}
