import { Root$, createPathEvent, Routing$ } from './root';
import { filter } from 'rxjs/operators';
import { isPathEvent } from './guards';
import IndexPage from './pages/index-page';
import NotFoundPage from './pages/404';
import { PageEnum } from './RoutingConstants';
import AboutPage from './pages/about';
import WormholePage from './pages/wormhole3';
import ScrumPokerPage from './pages/scrum-poker/ScrumPoker';
import LobbyPage from './pages/scrum-poker/Lobby';
import CreateUserPage from './pages/scrum-poker/CreateUser';
import Lightspeed from './pages/scrum-poker/lightspeed';

export default function setupRouting() {
    window.addEventListener('popstate', (e) => {
        console.log('popstate', e);
        Root$.next(createPathEvent(e.state.page_id, e.state.pathVariable));
    });

    Routing$.subscribe((ev) => {
        switch (ev.value) {
            case PageEnum.INDEX:
                // IndexPage();
                Lightspeed();
                break;
            // case PageEnum.ABOUT:
            //     AboutPage();
            //     break;
            // case PageEnum.WORMHOLE:
            //     //WormholePage();
            //     Lightspeed();
            //     break;
            case PageEnum.SCRUM_POKER:
                ScrumPokerPage(ev.pathVariable ?? '-1');
                break;
            case PageEnum.CREATE_USER:
                CreateUserPage(ev.pathVariable ?? '-1');
                break;
            case PageEnum.LOBBY:
                LobbyPage();
                break;
            default:
                NotFoundPage();
                break;
        }
    });
}
