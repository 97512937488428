import Page from '../components/page';
import E from '../elementary.setup';

export default function NotFoundPage() {
    /* prettier-ignore */
    return new E(Page('404 - Not Found'))
        .append('h2')
            .text('Oh No! We could not find that page!')
            .parent()
        .append('button')
            .on('click', () => window.history.go(-1))
            .text('Back');
}
