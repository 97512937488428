import { IAnimation, Position } from './IAnimate';
import { Animate } from './Animate3';

function clampCurrentTo(state: IAnimation) {
    const xDir = state.to.x - state.from.x;
    const yDir = state.to.y - state.from.y;
    const zDir = state.to.z - state.from.z;
    const topDir = state.to.top - state.from.top;
    const scaleDir = state.to.scale - state.from.scale;
    const hueDir = state.to.hueRotate - state.from.hueRotate;
    const xRotateDir = state.to.rotateX - state.from.rotateX;
    const yRotateDir = state.to.rotateY - state.from.rotateY;
    const zRotateDir = state.to.rotateZ - state.from.rotateZ;

    if (xDir >= 0) {
        state.current.x = state.current.x > state.to.x ? state.to.x : state.current.x;
    } else {
        state.current.x = state.current.x < state.to.x ? state.to.x : state.current.x;
    }

    if (zDir >= 0) {
        state.current.z = state.current.z > state.to.z ? state.to.z : state.current.z;
    } else {
        state.current.z = state.current.z < state.to.z ? state.to.z : state.current.z;
    }

    if (yDir >= 0) {
        state.current.y = state.current.y > state.to.y ? state.to.y : state.current.y;
    } else {
        state.current.y = state.current.y < state.to.y ? state.to.y : state.current.y;
    }

    if (topDir >= 0) {
        state.current.top = state.current.top > state.to.top ? state.to.top : state.current.top;
    } else {
        state.current.top = state.current.top < state.to.top ? state.to.top : state.current.top;
    }

    if (scaleDir >= 0) {
        state.current.scale = state.current.scale > state.to.scale ? state.to.scale : state.current.scale;
    } else if (scaleDir < 0) {
        state.current.scale = state.current.scale < state.to.scale ? state.to.scale : state.current.scale;
    }

    if (xRotateDir >= 0) {
        state.current.rotateX = state.current.rotateX > state.to.rotateX ? state.to.rotateX : state.current.rotateX;
    } else {
        state.current.rotateX = state.current.rotateX < state.to.rotateX ? state.to.rotateX : state.current.rotateX;
    }

    if (yRotateDir >= 0) {
        state.current.rotateY = state.current.rotateY > state.to.rotateY ? state.to.rotateY : state.current.rotateY;
    } else {
        state.current.rotateY = state.current.rotateY < state.to.rotateY ? state.to.rotateY : state.current.rotateY;
    }

    if (zRotateDir >= 0) {
        state.current.rotateZ = state.current.rotateZ > state.to.rotateZ ? state.to.rotateZ : state.current.rotateZ;
    } else {
        state.current.rotateZ = state.current.rotateZ < state.to.rotateZ ? state.to.rotateZ : state.current.rotateZ;
    }

    if (hueDir >= 0) {
        state.current.hueRotate =
            state.current.hueRotate > state.to.hueRotate ? state.to.hueRotate : state.current.hueRotate;
    } else {
        state.current.hueRotate =
            state.current.hueRotate < state.to.hueRotate ? state.to.hueRotate : state.current.hueRotate;
    }
}

export function performAnimate(state: IAnimation, percentage: number) {
    state.current.x = state.from.x + (state.to.x - state.from.x) * percentage;
    state.current.y = state.from.y + (state.to.y - state.from.y) * percentage;
    state.current.z = state.from.z + (state.to.z - state.from.z) * percentage;
    state.current.scale = state.from.scale + (state.to.scale - state.from.scale) * percentage;
    state.current.rotateX = state.from.rotateX + (state.to.rotateX - state.from.rotateX) * percentage;
    state.current.rotateY = state.from.rotateY + (state.to.rotateY - state.from.rotateY) * percentage;
    state.current.rotateZ = state.from.rotateZ + (state.to.rotateZ - state.from.rotateZ) * percentage;
    state.current.hueRotate = state.from.hueRotate + (state.to.hueRotate - state.from.hueRotate) * percentage;

    clampCurrentTo(state);

    state.el.style.transform = `translateX(${state.current.x}px) translateY(${state.current.y}px) translateZ(${state.current.z}px) scale(${state.current.scale}) rotateX(${state.current.rotateX}deg) rotateY(${state.current.rotateY}deg) rotateZ(${state.current.rotateZ}deg) `;
    state.el.style.filter = `hue-rotate(${state.current.hueRotate}deg)`;

    if (state.to.fillLinear) {
        state.el.style.background = `linear-gradient(to ${state.from.fillLinearDirection ?? 'top'}, ${
            state.to.fillLinear
        } ${percentage * 100}%, ${state.from.fillLinear ?? 'rgba(255,255,255,0.0)'} 0%, ${
            state.from.fillLinear ?? 'rgba(255,255,255,0.0)'
        })`;
    }

    if (state.to.fillRadial) {
        const [rad1, rad2] = state.to.fillRadialDirection ?? [Position.Center, Position.Center];
        state.el.style.background = `radial-gradient(circle at ${rad1} ${rad2}, ${state.to.fillRadial} ${
            percentage * 100
        }%, ${state.from.fillRadial ?? 'rgba(255,255,255,0.0)'} 0%, ${
            state.from.fillRadial ?? 'rgba(255,255,255,0.0)'
        })`;
    }

    if (state.to.top !== undefined && state.from.top !== undefined) {
        state.current.top = state.from.top + (state.to.top - state.from.top) * percentage;
        state.el.style.top = `${state.current.top}px`;
    }
}

export function performAnimateReverse(state: IAnimation, percentage: number) {
    state.current.x = state.to.x + (state.from.x - state.to.x) * percentage;
    state.current.y = state.to.y + (state.from.y - state.to.y) * percentage;
    state.current.z = state.to.z + (state.from.z - state.to.z) * percentage;
    state.current.scale = state.to.scale + (state.from.scale - state.to.scale) * percentage;
    state.current.rotateX = state.to.rotateX + (state.from.rotateX - state.to.rotateX) * percentage;
    state.current.rotateY = state.to.rotateY + (state.from.rotateY - state.to.rotateY) * percentage;
    state.current.rotateZ = state.to.rotateZ + (state.from.rotateZ - state.to.rotateZ) * percentage;
    state.current.hueRotate = state.to.hueRotate + (state.from.hueRotate - state.to.hueRotate) * percentage;

    clampCurrentTo(state);

    state.el.style.transform = `translateX(${state.current.x}px) translateY(${state.current.y}px) translateZ(${state.current.z}px) scale(${state.current.scale}) rotateX(${state.current.rotateX}deg)`;
    state.el.style.filter = `hue-rotate(${state.current.hueRotate}deg)`;

    if (state.to.fillLinear) {
        state.el.style.background = `linear-gradient(to ${state.from.fillLinearDirection ?? 'top'}, ${
            state.to.fillLinear
        } ${100 - percentage * 100}%, ${state.from.fillLinear ?? 'rgba(255,255,255,0.0)'} 0%, ${
            state.from.fillLinear ?? 'rgba(255,255,255,0.0)'
        })`;
    }

    if (state.to.fillRadial) {
        const [rad1, rad2] = state.to.fillRadialDirection ?? [Position.Center, Position.Center];
        state.el.style.background = `radial-gradient(circle at ${rad1} ${rad2}, ${state.to.fillRadial} ${
            100 - percentage * 100
        }%, ${state.from.fillRadial ?? 'rgba(255,255,255,0.0)'} 0%, ${
            state.from.fillRadial ?? 'rgba(255,255,255,0.0)'
        })`;
    }

    if (state.to.top !== undefined && state.from.top !== undefined) {
        state.current.top = state.to.top + (state.from.top - state.to.top) * percentage;
        state.el.style.top = `${state.current.top}px`;
    }
}

export function step<T>(
    animate: Animate<T>,
    anim: {
        start?: number;
        state: IAnimation;
        res: (state: Animate<T>) => void;
        rej: (err: any) => void;
    },
    reverse: boolean,
    timestamp: number,
    requestAnimFrame: (number) => void,
) {
    if (!anim.start) {
        anim.start = timestamp;
    }

    const duration = anim.state.duration * 1000;
    const elapsed = timestamp - anim.start;
    anim.state.currentPercentage = anim.state.yFn(elapsed / duration);

    if (reverse) {
        performAnimateReverse(anim.state, anim.state.currentPercentage);
    } else {
        performAnimate(anim.state, anim.state.currentPercentage);
    }

    if (elapsed < duration) {
        requestAnimFrame((timestamp) => step(animate, anim, reverse, timestamp, requestAnimFrame));
    } else {
        if (reverse) {
            performAnimateReverse(anim.state, 1);
        } else {
            performAnimate(anim.state, 1);
        }
        anim.res(animate);
    }
}
