export const GQL_URL = `https://eu-west-1.aws.realm.mongodb.com/api/client/v2.0/app/planningpokerrealm-uqanf/graphql`;
import * as Realm from 'realm-web';
import {
  EventTypes,
  IApolloClientEvent,
  IUserEvent,
  Root$,
} from '../../../root';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { filter } from 'rxjs/operators';
import { isApolloClientEvent } from '../../../guards';
import { GetRoom } from '../graphql/graphql-operations';
import { BehaviorSubject } from 'rxjs';

const app: Realm.App = new Realm.App({ id: 'planningpokerrealm-uqanf' });

// Create an anonymous credential
const credentials = Realm.Credentials.anonymous();

async function _login() {
  try {
    // Authenticate the user
    const user: Realm.User = await app.logIn(credentials);
    // `App.currentUser` updates to match the logged in user
    if (user.id !== app.currentUser.id) {
      throw new Error('wrong id');
    }
    return user;
  } catch (err) {
    console.error('Failed to log in', err);
  }
}

export function login() {
  _login().then((user) => {
    Root$.next({
      type: EventTypes.USER,
      user,
    } as IUserEvent);
    Root$.next({
      type: EventTypes.APOLLO_CLIENT,
      client: createApolloClient(user),
    } as IApolloClientEvent);
  });
}

export const client$ = new BehaviorSubject<IApolloClientEvent | null>(null);
Root$.pipe<IApolloClientEvent>(filter(isApolloClientEvent)).subscribe((e) =>
  client$.next(e),
);

export function createApolloClient(
  user: Realm.User,
): ApolloClient<NormalizedCacheObject> {
  const graphql_url = `https://realm.mongodb.com/api/client/v2.0/app/${app.id}/graphql`;

  return new ApolloClient({
    link: new HttpLink({
      uri: graphql_url,
      fetch: async (uri: RequestInfo, options: RequestInit) => {
        if (!options.headers) {
          options.headers = {} as Record<string, string>;
        }
        // Refreshing custom data also ensures a valid access token
        await user.refreshCustomData();
        const authenticatedOptions: RequestInit = {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${user.accessToken}`,
          },
        };
        return fetch(uri, authenticatedOptions);
      },
    }),
    cache: new InMemoryCache(),
  });
}
