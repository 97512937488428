// import E from '../lib/elementary';
import HeaderElem from '../components/header';
import E from '../elementary.setup';

export default function Page(title: string) {
    // prettier-ignore
    return new E('#app')
        .empty()
        .append('div')
            .append(['header-el', HeaderElem], title)
            .parent()
        .append('div.p-4.max-w-screen-xl.w-full.mx-auto')
            .el;
}
