import {
  IPathEvent,
  IEvent,
  EventTypes,
  IUserEvent,
  IApolloClientEvent,
  IUserEstimateEvent,
} from './root';

export function isPathEvent(event: IEvent): event is IPathEvent {
  return event.type === EventTypes.PATH;
}

export function isUserEvent(event: IEvent): event is IUserEvent {
  return event.type === EventTypes.USER;
}
export function isUserEstimateEvent(
  event: IEvent,
): event is IUserEstimateEvent {
  return event.type === EventTypes.USER_ESTIMATE;
}

export function isApolloClientEvent(
  event: IEvent,
): event is IApolloClientEvent {
  return event.type === EventTypes.APOLLO_CLIENT;
}
