import { PageEnum, navigateTo } from '../RoutingConstants';
import E from '../elementary.setup';

export default function HeaderElem(el: HTMLElement, title: string) {
    // prettier-ignore
    return new E(el)
        .empty()
        .append('header.h-12.relative.flex.align-center.px-2.border-b.border-gray-300')
            .append('div.text-center.text-2xl.flex.items-center.lg:hidden')
                .text(title)
                .parent()
            .append('button.z-10.flex.flex-col.p-3.absolute.right-0.top-0.bottom-0.lg:hidden')
                .on('click', (el, e) => el.el.nextElementSibling.classList.toggle('hidden'))
                .append('span.h-1.w-8.bg-gray-700').parent()
                .append('span.h-1.w-8.bg-gray-700.mt-1').parent()
                .append('span.h-1.w-8.bg-gray-700.mt-1').parent()
                .parent()
            .append('ul.hidden.absolute.left-0.z-20.mt-12.lg:mt-0.bg-white.w-full.lg:relative.lg:flex-1.lg:flex.flex-col.items-center.justify-center.lg:flex-row')
                .exec((el) =>
                    Object.keys(PageEnum).forEach((r) =>
                        new E(el).append([`nav-item-${r}`, NavItem], r),
                    ),
                )
                .parent()
            .parent()
        .el;
}

function NavItem(el: HTMLElement, route: PageEnum) {
    const name = route.toLowerCase().replace(/_/g, ' ');
    const isOnRoute = window.location.pathname === PageEnum[route];
    // prettier-ignore
    return new E(el)
        .append(`li.h-full.inline-block.px-4.py-2.cursor-pointer.transition-all.duration-75.hover:bg-gray-300.flex.items-center${isOnRoute? '.bg-blue-100' : ''}`)
            .on('click', (el, e) => (e.stopPropagation(), navigateTo(PageEnum[route])))
            .append('nav.capitalize.border-none.leading-none.bg-transparent.font-size-2xl')
                .text(name).el;
}
