import { Elementary } from './Elementary';

export class ElementaryArray<T extends ElementaryArray<T>> {
    protected _els: NodeListOf<HTMLElement> = null;

    constructor(els: NodeListOf<HTMLElement> | string) {
        if (typeof els === 'string') {
            this._els = document.querySelectorAll(els);
        } else {
            this._els = els;
        }
    }

    protected newArrayEl(els: NodeListOf<HTMLElement>): T {
        return (new ElementaryArray(els) as unknown) as T;
    }

    public on(event: string, cb: (e: any) => void) {
        this._els.forEach((e) => {
            e.addEventListener(event, cb);
        });
        return (this as unknown) as T;
    }

    public remove() {
        if (this._els.length === 0) {
            return (this as unknown) as T;
        }
        const parent = this._els.item(0).parentElement;
        this._els.forEach((e) => {
            e.remove();
        });
        return new Elementary<T>(parent);
    }

    public setAttribute(attr: string, val: any) {
        this._els.forEach((e) => {
            e.setAttribute(attr, val);
        });
        return (this as unknown) as T;
    }

    public text(t: string) {
        this._els.forEach((e) => {
            e.textContent = t;
        });
        return (this as unknown) as T;
    }
}

export default class EArray extends ElementaryArray<EArray> {}
