import Page from '../../components/page';
import { Easing } from '../../lib/animate/easingFunctions';
import { navigateTo, PageEnum } from '../../RoutingConstants';
import E from '../../elementary.setup';
import { IAnimatePlugin } from '../../lib/animate/Animate3';
import { Elementary } from '../../lib/Elementary';
import starwarsbg from './starwarsbg.jpg';

const Stars = (el: HTMLElement) => {
    /* prettier-ignore */
    const comp =
        new E(el)
            .empty()
            .append('div')
                .style({
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    width: '1px',
                    height: '1px',
                    borderRadius: '50%',
                    border: '2px solid rgba(255,255,255,0.8)',
                    transform: 'translateX(-50%) translateY(-50%)',
                });

    let handle = window.requestAnimationFrame(function anim() {
        new Array(50).fill(1).forEach(() => {
            const dir = 2 * Math.PI * Math.random() - Math.PI;
            const r = window.innerWidth;

            /* prettier-ignore */
            return comp
                .append('div')
                    .style({
                        width: '1px',
                        height: '1px',
                        borderRadius: '50%',
                        backgroundColor: `white`,
                        left: `${10*Math.random()*Math.cos(dir)}px`,
                        top: `${10*Math.random()*Math.sin(dir)}px`,
                        position: 'absolute',
                    })
                    .plugins.animate.init()
                        .to({
                            x: Math.cos(dir) * r,
                            y: Math.sin(dir) * r,
                            scale: 20.0,
                        })
                        .duration((Math.random()*0.63) + 1)
                        .ease(Easing.easeInCirc)
                        .open()
                            .finish()
                            .then((e) => {
                                e.elementary().el.remove();
                            })
        });
        handle = window.requestAnimationFrame(anim);
    });
    window.addEventListener('click', function listener() {
        window.cancelAnimationFrame(handle);
        navigateTo(PageEnum.LOBBY);
        window.removeEventListener('click', listener);
    });
    return comp.el;
};

export default function Lightspeed() {
    // prettier-ignore
    return new E(Page('Wormhole!!!'))
        .append('div')
        .style({
            background: `url(${starwarsbg})`,
            position: 'fixed',
            left: '0px',
            top: '0px',
            right: '0px',
            bottom: '0px',
            zIndex: '1000',
            fontFamily: "'Pathway Gothic One', sans-serif",
        })
        .append(['plan-wars', PlanWars])
}

function PlanWars(_el: HTMLElement) {
    // prettier-ignore
    new E(_el).append('h3.text-yellow-500')
        .style({
            position: 'fixed',
            color: 'black',
            fontFamily: "'Pathway Gothic One', sans-serif",
            width: '100vw',
            margin: '0 auto',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: '2',
            textAlign: 'center',
            fontSize: '750%',
            fontWeight: '600',
            letterSpacing: '6px',
            lineHeight: '150%',
            textShadow: `4px 4px 0 rgba(236, 201, 75, var(--text-opacity)), -4px 4px 0 rgba(236, 201, 75, var(--text-opacity)),
                4px -4px 0 rgba(236, 201, 75, var(--text-opacity)), -4px -4px 0 yellow`,
            transformOrigin: '50% 50%',
        })
        .text(`PLAN WARS`)
        .plugins.animate
            .init()
            .from({scale: 1})
            .to({scale: 0})
            .duration(5)
            .ease(Easing.easeInCirc)
            .open()
            .finish()
            .then((el) => 
                el.elementary()
                    .parent()
                        .execEl((el) => {
                            setTimeout(() => (el as unknown as Elementary<IAnimatePlugin<E>>)
                                .append('div')
                                    .style({
                                        position: 'relative',
                                        width: '100%',
                                        minHeight: '60vh',
                                        top: '-25px',
                                        backgroundImage: 'linear-gradient(0deg, transparent, black 75%)',
                                        zIndex: '1',
                                    })
                                    .parent()
                                .append('div.text-yellow-500')
                                    .style({
                                        position: 'relative',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        height: '800px',
                                        perspective: '400px',
                                        fontSize: '500%',
                                        fontWeight: '600',
                                        letterSpacing: '6px',
                                        fontStretch: 'semi-expanded',
                                        lineHeight: '150%',
                                        textAlign: 'justify'
                                    })
                                    .append('div')
                                        .style({
                                            transformOrigin: '50% 100%',
                                            position: 'relative',
                                            top:'99999px',
                                        })
                                        .append('p.text-yellow-500.text-center')
                                            .text('Episode I')
                                            .parent()
                                        .append('h1.text-yellow-500.text-center.pb-24.uppercase').style({fontSize:'120%'})
                                            // .text('Revenge Of The Product Owner')
                                            .text('Attack Of The Bugs')
                                            .parent()
                                        .append('p')
                                            .text(`
                                                It is a time of fear. After running into technical difficulties during the last sprint,
                                                leading to a botched release, the product owner has sworn to catch up.
                                            `)
                                            .parent()
                                        .append('p.mt-24')
                                            .text(`
                                                The team, bracing for a though time ahead, has stocked up on coffee
                                                and energy drinks. The bugs of the last sprint will chase them across the galaxy
                                                and into the new sprint, leaving room for few thoughts but those of plain survival.
                                            `)
                                            .parent()
                                        .append('p.mt-24')
                                            .text(`
                                                But there is hope. Before the sprint can start, the team has to estimate the new
                                                user stories. If they can convince the product owner that the stories are larger than they are,
                                                the team might have time to solve the bugs, and even play some ping pong...
                                            `)
                                            .parent()
                                        .plugins.animate.init()
                                            .from({
                                                rotateX: 20,
                                                z: 0,
                                                top: 300
                                            })
                                            .to({
                                                rotateX: 25,
                                                z: -2500,
                                                top: -6000
                                            })
                                            .duration(60)
                                            .ease(Easing.linear)
                                            .open()
                                            .finish()
                                                .then((el) => {
                                                    el.elementary().el.remove()
                                                    new E(_el)
                                                        .append(['start', Stars])
                                                })
                                , 2000);
                                return el;
                            })
            );
    return _el;
}
