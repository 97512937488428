import E from '../../elementary.setup';
import Page from '../../components/page';
import { createUser } from '../../pages/scrum-poker/ScrumPokerState';

export default function CreateUserPage(roomId: string) {
    // prettier-ignore
    return new E(Page('Create User'))
        .append('div.max-w-screen-md.mx-auto.flex.flex-row.justify-center.items-center.flex-wrap')
            .append('label.text-lg.w-full')
                .text('What is your name? ')
                .setAttribute('for', 'name')
                .parent()
            .append('input.field.flex-1')
                .setAttribute('id', 'name')
                .parent()
            .append('button.btn.btn-yellow.mt-2.md:mt-0.md:ml-4.md:flex-initial.w-full.md:w-auto')
                .text('Join')
                .on('click', (el, e) => {
                    const input: HTMLInputElement = document.querySelector('#name');
                    if (input && input.value.length > 0) {
                      createUser(input.value, roomId);
                    }
                })
}
