import setupRouting from './routing';
import { PageEnum, navigateTo } from './RoutingConstants';
import { login } from './pages/scrum-poker/state/mongodbrealm';

login();

setupRouting();

window.addEventListener('load', () => {
  const [, path, pathVariable] = window.location.pathname.split('/');
  console.log(path);
  console.log(pathVariable);
  const route =
    Object.keys(PageEnum).find((k) => PageEnum[k] === `/${path}`) ??
    PageEnum.INDEX;
  navigateTo(PageEnum[route], pathVariable);
});
