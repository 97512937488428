import { IElementaryCore } from '../IElementary';
import { Easing } from './easingFunctions';

export enum Position {
    Left = 'left',
    Right = 'right',
    Top = 'top',
    Bottom = 'bottom',
    Center = 'center',
    Side = 'side',
}

export interface IAnimationProps {
    x?: number;
    y?: number;
    z?: number;
    top?: number;
    scale?: number;
    hueRotate?: number;
    rotateX?: number;
    rotateY?: number;
    rotateZ?: number;
    fillLinear?: string;
    fillLinearDirection?: Position;
    fillRadial?: string;
    fillRadialDirection?: [Position, Position];
}

export type AnimType = IElementaryCore<AnimType> & {
    anim: IAnimateFn;
    animate: () => AnimType;
};

export interface IAnimation {
    el: HTMLElement;
    from: IAnimationProps;
    to: IAnimationProps;
    current: IAnimationProps;
    duration: number; // seconds
    ease?: Easing;
    yFn: (x: number) => number;
    currentPercentage: number;
}

export interface IAnimateFn {
    from: (from: IAnimationProps) => AnimType;
    to: (to: IAnimationProps) => AnimType;
    duration: (duration: number) => AnimType;
    open: () => Promise<AnimType>;
    close: () => Promise<AnimType>;
    yFn: (fn: (x: number) => number) => AnimType;
    ease: (ease: Easing) => AnimType;
    state: IAnimation;
}
