import E from '../../elementary.setup';
import Page from '../../components/page';
import { createRoom } from '../../pages/scrum-poker/ScrumPokerState';

export default function LobbyPage() {
    // prettier-ignore
    return new E(Page('Lobby'))
        .append('div.max-w-screen-md.mx-auto.flex.flex-row.justify-center.items-center.flex-wrap')
            .append('label.text-lg.w-full')
                .text('Name your room: ')
                .setAttribute('for', 'room-title')
                .parent()
            .append('input.field.flex-1')
                .setAttribute('id', 'room-title')
                .parent()
            .append('button.btn.btn-yellow.mt-2.md:mt-0.md:ml-4.md:flex-initial.w-full.md:w-auto')
                .text('Create Room')
                .on('click', () => {
                    const date = new Date();
                    const roomKey = parseInt(`${date.getUTCDate().toString().slice(-1)}${date.getUTCHours()}${date.getUTCMinutes()}${date.getUTCSeconds()}${(Math.random()*89 + 10).toFixed(0)}`);
                    const title = (document.querySelector('#room-title') as HTMLInputElement)?.value ?? '';
                    createRoom(roomKey, title);
                })
}
