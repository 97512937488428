export enum PageEnum {
    INDEX = '/',
    // TEST = '/test',
    // ABOUT = '/about',
    // WORMHOLE = '/wormhole',
    LOBBY = '/lobby',
    CREATE_USER = '/create-user',
    SCRUM_POKER = '/scrum-poker',
    // PAGE_NOT_FOUND = '/404',
}

export function getPageState(page: PageEnum): typeof window.history.state {
    return {
        page_id: page,
    };
}

export function navigateTo(page_id: PageEnum, pathVariable?: string) {
    window.history.pushState(
        { page_id, pathVariable },
        PageEnum[page_id],
        pathVariable ? `${page_id}/${pathVariable}` : page_id,
    );
    window.dispatchEvent(
        new PopStateEvent('popstate', {
            state: { page_id, pathVariable },
        }),
    );
}
