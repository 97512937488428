import gql from 'graphql-tag';

export const CreateUser = gql`
    mutation CreateUser($name: String!) {
  insertOneEstimate(data: {name: $name, estimate: -1}) {
    _id
    name
    estimate
  }
}
    `;
export const AddUserToRoom = gql`
    mutation AddUserToRoom($roomId: ObjectId!, $estimateId: ObjectId!) {
  addUser(input: {roomId: $roomId, estimateId: $estimateId}) {
    _id
    title
    key
    estimates {
      _id
      name
      estimate
    }
  }
}
    `;
export const RemoveUserFromRoom = gql`
    mutation RemoveUserFromRoom($roomId: ObjectId!, $estimateId: ObjectId!) {
  removeUser(input: {roomId: $roomId, estimateId: $estimateId}) {
    _id
    title
    key
    estimates {
      _id
      name
      estimate
    }
  }
}
    `;
export const UpdateEstimate = gql`
    mutation UpdateEstimate($estimateId: ObjectId!, $estimate: Int!) {
  updateOneEstimate(query: {_id: $estimateId}, set: {estimate: $estimate}) {
    _id
  }
}
    `;
export const CreateRoom = gql`
    mutation CreateRoom($key: Int!, $title: String) {
  insertOneRoom(data: {key: $key, title: $title}) {
    _id
    title
    key
    estimates {
      _id
      name
      estimate
    }
  }
}
    `;
export const ToggleShowEstimates = gql`
    mutation ToggleShowEstimates($id: ObjectId!, $show: Boolean!) {
  updateOneRoom(query: {_id: $id}, set: {show: $show}) {
    _id
    title
    key
    show
    estimates {
      _id
      name
      estimate
    }
  }
}
    `;
export const GetRoom = gql`
    query GetRoom($key: Int!) {
  room(query: {key: $key}) {
    _id
    title
    key
    show
    estimates {
      _id
      name
      estimate
    }
  }
}
    `;