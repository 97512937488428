import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isPathEvent } from './guards';
import { Estimate } from './pages/scrum-poker/graphql/types';
import { PageEnum } from './RoutingConstants';

export enum EventTypes {
  INIT,
  PATH,
  USER_ESTIMATE,
  USER,
  APOLLO_CLIENT,
}

export interface IEvent {
  type: EventTypes;
}

export interface IPathEvent extends IEvent {
  type: EventTypes.PATH;
  value: PageEnum;
  pathVariable?: string;
}

export interface IUserEstimateEvent extends IEvent {
  type: EventTypes.USER_ESTIMATE;
  estimate: Pick<Estimate, '_id' | 'name' | 'estimate'> | null;
}

export interface IUserEvent extends IEvent {
  type: EventTypes.USER;
  user: Realm.User | null;
}
export interface IApolloClientEvent extends IEvent {
  type: EventTypes.APOLLO_CLIENT;
  client: ApolloClient<NormalizedCacheObject> | null;
}

export function createPathEvent(
  path: PageEnum,
  pathVariable?: string,
): IPathEvent {
  return {
    type: EventTypes.PATH,
    value: path,
    pathVariable,
  };
}

export const Root$ = new BehaviorSubject<IEvent>({ type: EventTypes.INIT });

export const Routing$ = Root$.pipe(filter(isPathEvent));
