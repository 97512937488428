import E from '../../../elementary.setup';
import { Room } from '../graphql/types';
import { removeUser } from '../ScrumPokerState';

export function UserEstimates(el: HTMLElement, data: Room) {
    // prettier-ignore
    return new E(el)
        .append('div.w-full.relative')
            .append('table.w-full.table-auto')
                .append('thead.font-bold')
                    .append('tr')
                        .append('td.px-4.py-2')
                            .text('Name')
                            .parent()
                        .append('td.px-4.py-2')
                            .text('Estimate')
                            .parent()
                        .parent()
                    .parent()
                .append('tbody')
                    .execEl((el) => data.estimates.forEach((est) =>
                        el
                            .append('tr')
                                .append('td.border.px-4.py-2')
                                    .text(est.name)
                                    .parent()
                                .append('td.border.px-4.py-2.text-right')
                                    .text(data.show ? 
                                            est.estimate > -1 ? 
                                                est.estimate.toString() : 
                                                    est.estimate === -2 ? '☕' : '-'
                                                : est?.estimate === -1 ? '-' : '*')
                                    .parent()
                                .append('button.btn.btn-red.ml-2.absolute')
                                    .text('🗑')
                                    .on('click', (el, e) => removeUser(data._id, est._id))
                    ))
                .parent()
            .parent()
        .parent()
    .el;
}

