import E from '../../elementary.setup';
import Page from '../../components/page';
import {
    clearEstimates,
    selectedCard$,
    toggleShowEstimates,
    userEstimates$,
} from '../../pages/scrum-poker/ScrumPokerState';
import { UserEstimates } from '../../pages/scrum-poker/components/UserEstimates';
import { client$ } from './state/mongodbrealm';
import { GetRoom } from './graphql/graphql-operations';
import { GetRoomQuery } from './graphql/types';
import { BehaviorSubject } from 'rxjs';
import { Cards } from './components/Cards';
import { Routing$ } from '../../root';
import { navigateTo, PageEnum } from '../../RoutingConstants';

export default function ScrumPokerPage(key: string) {
    const room$ = new BehaviorSubject<GetRoomQuery | null>(null);
    client$.subscribe((clientEvent) => {
        if (clientEvent?.client) {
            const unsubWatchQuery = clientEvent.client
                .watchQuery({
                    query: GetRoom,
                    variables: {
                        key: parseInt(key),
                    },
                    pollInterval: 5000,
                })
                .subscribe((e) => {
                    room$.next(e.data);
                });

            let i = 0;
            const unsub = Routing$.subscribe(
                (e) => (i > 0 && unsubWatchQuery.unsubscribe(), i > 0 && unsub.unsubscribe(), ++i),
            );
        }
    });

    if (!userEstimates$.value?.estimate?._id) {
        setTimeout(() => {
            const roomKey = window.location.pathname.split('/').slice(-1)[0];
            navigateTo(PageEnum.CREATE_USER, roomKey);
        }, 500);
    }

    // prettier-ignore
    return new E(Page('Plan Wars'))
        .append('div')
            .onStream(room$, (el, event: GetRoomQuery | null) =>
                el
                    .queryOrCreate('div.room', 'div.room.lg:grid.lg:grid-cols-5')
                        .empty()
                        .execEl((el) => {
                            if (event?.room) {
                                el
                                    .append('div.controls.lg:col-span-1')
                                        .append('h1.text-4xl')
                                            .text(event.room.title)
                                            .parent()
                                        .append('h2.text-2xl')
                                            .text(event.room.key.toString())
                                            .parent()
                                        .append('button.btn.btn-yellow')
                                            .text('Show')
                                            .on('click', () => room$.value.room._id && toggleShowEstimates(room$.value.room._id, true))
                                            .parent()
                                        .append('button.btn.btn-yellow.ml-4')
                                            .text('Clear')
                                            .on('click', () => room$.value.room && clearEstimates(room$.value.room))
                                            .parent()
                                        .parent()
                                    .append('div.lg:col-span-4')
                                        .append('div')
                                            .onStream(selectedCard$, (el, event: number) => {
                                                el.append(['cards', Cards], event, (num: number) =>
                                                    selectedCard$.next(num),
                                                );
                                            })
                                        .parent()
                                    .append(['user-estimates', UserEstimates], event.room);
                                }
                            }),
                    )
}
